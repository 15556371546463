<template>
  <div class="body_functionAdd">
    <div v-loading="loading">
      <div class="child-top">
        <div class="top_title">
          <span></span>
          基本信息
        </div>
        <div class="input-from">
          <div class="operation-button">
            <!-- @click="$down('/p/test/downTest',$route.query.uuid)" -->
            <el-button
              class="down_btn"
              @click="$down('/p/test/downTemplate', $route.query.uuid)"
              round
              v-if="
                $route.query.name !== 'look' && $route.query.uuid !== undefined
              "
            >
              <i class="iconfont icon-xiazai-icon" style="color: #4bb3ff"></i>
              <span>下载模板</span>
            </el-button>
            <el-button
              class="brotherBtns importFile load room"
              round
              style="background: #35c8cb"
              v-if="
                $route.query.name !== 'look' && $route.query.uuid !== undefined
              "
            >
              <div class="fileInp">
                <!-- <input @input="$importFile($event,'Strength')" type="file"> -->
                <input @input="$importFile($event, 'enginery')" type="file" />
              </div>
              <i class="iconfont icon-daoru-icon" style="color: #f5a623"></i>
              <span>导入</span>
            </el-button>
            <el-button
              class="export_btn"
              round
              @click="exportSave"
              v-if="$route.query.name !== 'look'"
            >
              <span>保存</span>
            </el-button>
            <!-- <el-button class="export_btn" @click="$expor('/p/test/exportTest',$route.query.uuid)" round style="background:#696EF8;" v-if="$route.query.name == 'look'"> -->
            <el-button
              class="export_btn"
              @click="$expor('/p/test/export', $route.query.uuid)"
              round
              style="background: #696ef8"
              v-if="$route.query.name == 'look'"
            >
              <span>导出</span>
            </el-button>
            <span
              class="fanhui"
              @click="
                () => {
                  $router.push({
                    path: '/textItem/enduranceText',
                    query: { type: 6 },
                  });
                }
              "
              ><i class="iconfont iconfanhui"></i>返回</span
            >
          </div>
        </div>
      </div>
      <div class="body_functionAdd_top bottom_btn">
        <el-form
          v-if="$route.query.name !== 'look'"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          class="demo_ruleForm"
          :label-position="'left'"
          :inline-message="false"
        >
          <div class="item_flex">
            <el-form-item label="标题" prop="title">
              <el-input
                v-model="ruleForm.title"
                class=""
                placeholder="请输入标题"
                :disabled="$route.query.name == 'look'"
              ></el-input>
            </el-form-item>
            <el-form-item label="测试日期" prop="date">
              <el-date-picker
                v-model="ruleForm.date"
                value-format="yyyy-MM-dd"
                type="date"
                :disabled="$route.query.name == 'look'"
                placeholder="请选择日期"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item label="运动队" prop="department_uuid">
            <el-cascader
              clearable
              style="width: 100%"
              v-model="ruleForm.department_uuid"
              placeholder="请选择所属运动队"
              @change="getSportsMan(true)"
              :show-all-levels="false"
              :options="$store.state.treeMenu"
              :disabled="$route.query.name == 'look'"
              :props="{
                label: 'name',
                value: 'uuid',
                emitPath: false,
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="运动员" prop="sportsmanId">
            <el-select
              v-model="ruleForm.sportsmanId"
              filterable
              multiple
              placeholder="请选择运动员"
              style="width: 100%"
              @change="sportsmanChange"
              :disabled="$route.query.name == 'look'"
            >
              <el-option
                v-for="item in sportsmanList"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="测试指标" prop="textIndex">
            <!-- <el-input v-model="ruleForm.textIndex" placeholder="请选择测试指标" @focus="selectTextIndex"></el-input> -->
            <div
              class="text_demo"
              @click="selectTextIndex"
              placeholder="请选择测试指标"
            >
              <p v-if="!demoSelectData.length">
                {{ $route.query.name !== "look" ? "请选择测试指标" : "" }}
              </p>
              <span v-else v-for="(item, index) in demoSelectData" :key="index"
                >{{ item.name }}
                <i
                  class="el-icon-close"
                  v-show="$route.query.name !== 'look'"
                  @click.stop="demoCancel(item.uuid, index)"
                ></i>
              </span>
            </div>
          </el-form-item>
          <div class="item_flex">
            <el-form-item label="教练" prop="coach">
              <el-input
                v-model="ruleForm.coach"
                :placeholder="$route.query.name !== 'look' ? '请输入教练' : ''"
                :disabled="$route.query.name == 'look'"
              ></el-input>
            </el-form-item>
            <el-form-item label="测试地址" prop="address">
              <el-select
                v-model="ruleForm.address"
                clearable
                :placeholder="
                  $route.query.name !== 'look' ? '请选择测试地址' : ''
                "
                style="width: 80%"
                :disabled="$route.query.name == 'look'"
              >
                <el-option
                  v-for="item in addressList"
                  :key="item.uuid"
                  :label="item.address"
                  :value="item.uuid"
                >
                  <span style="float: left">{{ item.address }}</span>
                  <span style="float: right" class="address_del"
                    ><i
                      class="el-icon-circle-close"
                      @click="address_del(item.uuid)"
                    ></i
                  ></span>
                </el-option>
              </el-select>
              <el-button
                type="text"
                style="margin-left: 8%; color: #0055e9"
                @click="newAddress"
                v-show="$route.query.name !== 'look'"
                >新增地址</el-button
              >
            </el-form-item>
          </div>
          <el-form-item label="备注" prop="demo">
            <el-input
              v-model="ruleForm.demo"
              :placeholder="
                $route.query.name !== 'look'
                  ? '备注：可填写注意事项、特殊要求、修改内容等说明信息'
                  : ''
              "
              :disabled="$route.query.name == 'look'"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-form
          v-else
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          class="demo_ruleForm"
          :label-position="'left'"
          :inline-message="false"
        >
          <div class="item_flex">
            <el-form-item label="标题" prop="title">
              <p class="look_opp">{{ ruleForm.title }}</p>
            </el-form-item>
            <el-form-item label="测试日期" prop="date">
              <p class="look_opp">{{ ruleForm.date }}</p>
            </el-form-item>
          </div>
          <el-form-item label="运动队" prop="department_uuid">
            <p class="look_opp">{{ ruleForm.department_name }}</p>
          </el-form-item>
          <el-form-item label="运动员" prop="sportsmanId">
            <p class="look_opp">{{ ruleForm.staff_name_str }}</p>
          </el-form-item>
          <el-form-item label="测试指标" prop="textIndex">
            <p class="look_opp">
              <span v-for="(value, index) in demoSelectData" :key="index"
                >{{ index !== 0 ? "，" : "" }}{{ value.name }}</span
              >
            </p>
          </el-form-item>
          <div class="item_flex">
            <el-form-item label="教练" prop="coach">
              <p class="look_opp">{{ ruleForm.coach }}</p>
            </el-form-item>
            <el-form-item label="测试地址" prop="address">
              <p class="look_opp">{{ ruleForm.address_name }}</p>
            </el-form-item>
          </div>
          <el-form-item label="备注" prop="demo">
            <p class="look_opp">{{ ruleForm.demo }}</p>
          </el-form-item>
        </el-form>
      </div>
      <!-- 耐力指标测试表 -->
      <div class="GJ_content" v-if="cateNameData.length">
        <div class="top_title">
          <span></span>
          耐力指标
        </div>
        <el-table
          :data="GJ_tableData"
          style="width: 100%"
          class="GJ_tableData"
          :span-method="spanMethod"
        >
          <el-table-column label="序号" type="index" width="45" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="staff_name"
            label="运动员"
            width="60"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="45" align="center">
            <template slot-scope="scope">
              {{ scope.row.sex == "1" ? "男" : "女" }}
            </template>
          </el-table-column>
          <el-table-column
            :label="item.cate_name"
            align="center"
            v-for="(item, index) in GJObject"
            :key="index"
          >
            <el-table-column
              :label="
                itm.norm_name +
                (itm.unit_name !== '' && itm.unit_name
                  ? '(' + itm.unit_name + ')'
                  : '')
              "
              align="center"
              v-for="(itm, idx) in item.norms"
              :key="idx"
            >
              <template slot-scope="scope">
                <span v-if="$route.query.name == 'look'">{{
                  scope.row.cates[index].norms[idx].norm_value
                }}</span>
                <el-input
                  v-else
                  v-model="scope.row.cates[index].norms[idx].norm_value"
                  @input="
                    ruleValueChange(itm.norm_name, scope.$index, index, idx)
                  "
                  @blur="
                    ALLMean(
                      scope.row.sex,
                      scope.$index,
                      index,
                      idx,
                      item.cate_name,
                      itm.norm_name
                    )
                  "
                  :disabled="
                    scope.row.staff_name == '男子-团队平均值' ||
                    scope.row.staff_name == '女子-团队平均值' ||
                    $route.query.name == 'look'
                  "
                ></el-input>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div class="GJ_echarts">
        <div class="top_title" v-if="cateNameData.length">
          <span></span>
          耐力指标测试图
        </div>
        <div
          class="JR_echarts"
          v-for="(item, index) in cateNameData"
          :key="index"
        >
          <div class="e_left_sele">
            <el-select
              v-model="Options[item].select"
              placeholder="请选择指标"
              @change="sexChange(item, index)"
            >
              <el-option
                v-for="item in Options[item].norms"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="Options[item].sex"
              placeholder="请选择性别"
              clearable
              @change="sexChange(item, index)"
            >
              <el-option
                key="1"
                label="男"
                value="1"
                v-if="GJoldman == '1' || GJoldman == '3'"
              >
              </el-option>
              <el-option
                key="2"
                label="女"
                value="2"
                v-if="GJoldman == '2' || GJoldman == '3'"
              >
              </el-option>
            </el-select>
          </div>
          <div class="wl_e_line" :id="`Line${index}`"></div>
        </div>
      </div>
      <!-- 测试评价 -->
      <div class="test_content">
        <div class="top_title">
          <span></span>
          测试评价
        </div>
        <div
          class="test_editor"
          id="editor"
          ref="editorElem"
          style="width: 100%"
        ></div>
      </div>
    </div>

    <!-- 新建地址 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogAddress"
      :before-close="address_cancel"
      title="新增地址"
      width="55%"
      center
    >
      <div class="addressForm_dialog">
        <el-form
          :model="addressForm"
          :rules="addressRules"
          ref="addressForm"
          label-width="90px"
          class="demo_ruleForm addressForm_dialog"
          :label-position="'left'"
          :inline-message="false"
        >
          <div class="item_flex">
            <el-form-item label="国家" prop="country">
              <el-select
                v-model="addressForm.country"
                placeholder="请选择国家"
                style="width: 100%"
                @change="countryChange('country')"
              >
                <el-option
                  v-for="item in countryList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="省市" prop="city">
              <el-select
                v-model="addressForm.city"
                placeholder="请选择省市"
                style="width: 100%"
                @change="countryChange('city')"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item_flex">
            <el-form-item label="市区" prop="region">
              <el-select
                v-model="addressForm.region"
                placeholder="请选择市区"
                style="width: 100%"
              >
                <el-option
                  v-for="item in regionList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="输入详细地址" prop="address">
              <el-input
                v-model="addressForm.address"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="form-bottom-button">
        <el-button
          @click="address_save"
          type="primary"
          v-no-more-click
          class="save_btn"
          round
          >保存</el-button
        >
        <el-button @click="address_cancel" class="cancel_btn" round
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 测试指标 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogTextIndex"
      :before-close="text_cancel"
      title="选择指标"
      width="50%"
      center
    >
      <div class="ModifyDoalog">
        <div class="textDialog_left">
          <div class="textDialog_left_input">
            <el-input
              @keyup.enter.native="selectTextIndex"
              class="left-child-input left-child-input-content"
              clearable
              placeholder="请输入指标名称"
              suffix-icon="iconfont iconsousuo"
              v-model="inputValue"
            >
            </el-input>
          </div>
          <h2>指标列表</h2>
          <div class="tree_demo" v-loading="load">
            <el-tree
              empty-text="暂无数据"
              :data="textTreeData"
              ref="textTreeNode"
              show-checkbox
              node-key="uuid"
              :props="defaultProps"
              :check-strictly="false"
              :check-on-click-node="false"
              :filter-node-method="filterNode"
              @check="checkChange"
            >
            </el-tree>
          </div>
        </div>
        <div class="textDialog_right">
          <p>
            已选指标 ( {{ selectNum }}/{{ textAllNum }} )<span
              @click="clickTreeAll"
              >清空</span
            >
          </p>
          <div class="tree_demo">
            <el-tree
              :data="selectTreeData"
              node-key="uuid"
              :props="defaultProps"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <i
                    class="iconfont iconshanchu-moren"
                    @click="textTreeCancel(node, data)"
                    v-if="data.showCheckbox"
                  ></i>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <p class="bz_content">
        *注：一个指标同属于多个分类，指标只呈现在一个分类中
      </p>
      <div class="form-bottom-button">
        <el-button
          @click="text_save"
          type="primary"
          v-no-more-click
          class="save_btn"
          round
          >保存</el-button
        >
        <el-button @click="text_cancel" class="cancel_btn" round
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import E from "wangeditor";
export default {
  data() {
    return {
      load: true,
      editor: null,
      editorContent: "",
      loading: false,
      newAddressName: "",
      textIndex: [],
      addressList: [],
      sportsmanList: [],
      countryList: [],
      cityList: [],
      regionList: [],
      textTreeData: [],
      oldTreeData: [],
      selectTreeData: [],
      saveSelectTreeData: [],
      demoSelectData: [],
      oldAddress: "",
      inputValue: "",
      textAllNum: 0,
      selectNum: 0,
      selectTextName: [],

      GJObject: [],
      GJ_selectName: [],
      GJ_tableData: [],

      dialogAddress: false,
      dialogTextIndex: false,

      ruleForm: {
        title: "",
        data: null,
        department_uuid: "",
        sportsmanId: [],
        address: "",
      },

      addressForm: {
        country: "",
        city: "",
        region: "",
        address: "",
      },

      defaultProps: {
        children: "norms",
        label: "name",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        date: { required: true, message: "请选择测试日期", trigger: "blur" },
        department_uuid: {
          required: true,
          message: "请选择运动队",
          trigger: "change",
        },
        sportsmanId: {
          required: true,
          message: "请选择运动员",
          trigger: "change",
        },
      },
      addressRules: {
        country: { required: true, message: "请选择国家", trigger: "blur" },
      },
      GJ_sex: "",
      GJoldman: 0,
      GJ_object: {
        cate_uuid: "",
        cate_name: "",
        staffs: null,
      },
      cateNameData: [],
      // Options: {
      //   '有氧能力': {
      //     sex: '',
      //     select: '',
      //     norms: ['']
      //   }
      // }
      newDelData: [],
    };
  },
  watch: {
    inputValue(val) {
      this.$refs.textTreeNode.filter(val);
    },
  },
  mounted() {
    this.getAddressData();
    this.editor = new E(this.$refs.editorElem);
    // 编辑器的事件，每次改变会获取其html内容
    this.editor.config.onchange = (html) => {
      this.editorContent = html;
    };
    this.editor.config.zIndex = 1000;
    // this.editor.config.uploadImgServer = this.$store.state.img_url + '/p/annex/add'
    // this.editor.config.uploadFileName = 'file'
    this.editor.config.uploadImgShowBase64 = true;
    this.editor.config.menus = [
      // 菜单配置
      "head", // 标题
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote", // 引用
      "image", // 插入图片
      "table", // 表格
      "code", // 插入代码
    ];
    this.editor.create(); // 创建富文本实例
    if (this.$route.query.name == "look") {
      this.editor.disable();
    }

    if (this.$route.query.uuid) {
      this.loading = true;
      this.selectTextIndex("编辑");

      this.$axios
        .post(
          "/endurance/read",
          this.$qs({
            uuid: this.$route.query.uuid,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.ruleForm = {
              title: res.data.data.title,
              date: res.data.data.test_date,
              department_uuid: res.data.data.department_uuid,
              department_name: res.data.data.department_name,
              coach: res.data.data.coach,
              address: res.data.data.address_uuid,
              address_name: res.data.data.address,
              demo: res.data.data.demo,
              staff_uuid_str: res.data.data.staff_uuid_str,
              staff_name_str: res.data.data.staff_name_str,
            };
            if (
              this.ruleForm.address_name !== "" ||
              this.ruleForm.address !== ""
            ) {
              this.addressList = this.$dataBack(
                this.addressList,
                {
                  address: this.ruleForm.address_name,
                  uuid: this.ruleForm.address,
                },
                "uuid",
                "address"
              );
            }
            this.editorContent = res.data.data.summary;
            this.editor.txt.html(res.data.data.summary);
            this.getSportsMan();
            this.$set(
              this.ruleForm,
              "sportsmanId",
              this.ruleForm.staff_uuid_str.split(",")
            );
            this.ruleForm = Object.assign({}, this.ruleForm);
            this.saveSelectTreeData = res.data.data.cate_norm;
            this.selectTreeData = res.data.data.cate_norm;
            this.saveSelectTreeData.forEach((item) => {
              if (item.norms.length) {
                item.norms.forEach((itm) => {
                  this.demoSelectData.push(itm);
                });
              } else {
                this.demoSelectData.push(item);
              }
              this.selectTextName.push(item.name);
            });

            res.data.data.detail.forEach((item) => {
              let obj = {
                sex: item.sex,
                staff_name: item.staff_name,
                staff_uuid: item.staff_uuid,
                total_value: "",
                cates: [],
              };
              for (var i in item.cates) {
                let c_obj = {
                  cate_name: item.cates[i].cate_name,
                  cate_uuid: item.cates[i].cate_uuid,
                  norms: [],
                };
                for (var o in item.cates[i].norms) {
                  c_obj.norms.push({
                    json_value: [],
                    norm_uuid: item.cates[i].norms[o].norm_uuid,
                    norm_value: item.cates[i].norms[o].norm_value,
                    norm_name: o,
                    value_type: 0,
                    unit_name: item.cates[i].norms[o].unit_name,
                  });
                }
                obj.cates.push(JSON.parse(JSON.stringify(c_obj)));
              }
              this.GJ_tableData.push(JSON.parse(JSON.stringify(obj)));
            });

            if (this.GJ_tableData.length) {
              for (var i in this.GJ_tableData[0].cates) {
                this.cateNameData.push(this.GJ_tableData[0].cates[i].cate_name);
                let c_obj = {
                  cate_name: this.GJ_tableData[0].cates[i].cate_name,
                  cate_uuid: this.GJ_tableData[0].cates[i].cate_uuid,
                  norms: [],
                };
                for (var o in this.GJ_tableData[0].cates[i].norms) {
                  // c_obj.norms.push(this.GJ_tableData[0].cates[i].norms[o])
                  c_obj.norms.push({
                    json_value: [],
                    norm_name: this.GJ_tableData[0].cates[i].norms[o].norm_name,
                    norm_uuid: this.GJ_tableData[0].cates[i].norms[o].norm_uuid,
                    norm_value: "",
                    value_type: "",
                    unit_name: this.GJ_tableData[0].cates[i].norms[o].unit_name,
                  });
                }
                this.GJObject.push(JSON.parse(JSON.stringify(c_obj)));
              }
            }
            this.getSlectName();
            this.sportsmanChange();
            // 修复loading先加载bug
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          } else {
            this.loading = false;
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    valueInput() {
      console.log(this.FMS_tableData);
    },
    // 获取运动员列表
    getSportsMan(type) {
      this.sportsmanList = [];
      this.ruleForm.sportsmanId = [];
      if (!this.ruleForm.department_uuid) {
        return false;
      }
      let obj = {
        department_uuid: this.ruleForm.department_uuid,
        type: "staff",
      };
      if (this.$route.query.uuid && !type) {
        obj.uuid = this.$route.query.uuid;
        obj.area = "test";
      }
      this.$axios
        .post("p/Staff/getStaffByStation", this.$qs(obj))
        .then((res) => {
          if (res.data.code == 0) {
            this.sportsmanList = res.data.data;
            this.sportsmanList.unshift({
              name: "全选",
              uuid: "0",
            });
            if (type) {
              this.GJ_tableData = [];
              this.ALLMean();
            }
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取地址列表
    getAddressData(name) {
      this.$axios
        .get("/p/address/all")
        .then((res) => {
          if (res.data.code == 0) {
            this.addressList = res.data.data;
            if (name == "new") {
              this.ruleForm.address = this.addressList[0].uuid;
            }
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取城市列表
    getCountryData() {
      this.$axios
        .get("/p/city/all")
        .then((res) => {
          if (res.data.code == 0) {
            this.countryList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    countryChange(name) {
      if (name == "country") {
        this.countryList.map((item) => {
          this.addressForm.city = "";
          this.addressForm.region = "";
          this.regionList = [];
          if (item.id == this.addressForm.country) {
            this.cityList = item.children;
          }
        });
      } else if (name == "city") {
        this.addressForm.region = "";
        this.cityList.map((item) => {
          if (item.id == this.addressForm.city) {
            this.regionList = item.children;
          }
        });
      }
    },
    // 新建地址
    newAddress() {
      this.getCountryData();
      this.dialogAddress = true;
    },
    address_save() {
      this.$refs["addressForm"].validate((valid) => {
        if (valid) {
          this.$axios
            .post(
              "/p/address/add",
              this.$qs({
                country_id: this.addressForm.country,
                province_id: this.addressForm.city,
                city_id: this.addressForm.region,
                address: this.addressForm.address,
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: res.data.message,
                });
                this.address_cancel();
                this.getAddressData("new");
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    address_cancel() {
      this.dialogAddress = false;
      this.$refs.addressForm.resetFields();
    },
    address_del(uuid) {
      setTimeout(() => {
        this.$set(this.ruleForm, "address", "");
      }, 0);
      this.$axios
        .post(
          "/p/address/del",
          this.$qs({
            uuid: uuid,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.getAddressData();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 选择测试指标
    selectTextIndex(name) {
      if (this.$route.query.name == "look") {
        return false;
      }
      this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData));
      if (name !== "编辑") {
        this.dialogTextIndex = true;
      }
      let obj = {
        type: "6",
        keyword: this.inputValue,
      };
      if (this.$route.query.uuid) {
        obj.test_uuid = this.$route.query.uuid;
      }
      this.$axios
        .post(
          "p/cate/structNormCount",
          this.$qs({
            type: "6",
          })
        )
        .then((res) => {
          this.textAllNum = res.data.data;
        });
      this.$axios
        .post("p/cate/structNorm", this.$qs(obj))
        .then((res) => {
          if (res.data.code == 0) {
            (this.load = false),
              res.data.data.forEach((item) => {
                if (item.builtin == 1) {
                  item.norms.forEach((itm) => {
                    itm.builtin = 1;
                    itm.showCheckbox = false;
                  });
                  item.builtin = 0;
                  item.showCheckbox = true;
                } else {
                  item.showCheckbox = true;
                  item.norms.forEach((itm) => {
                    itm.showCheckbox = true;
                  });
                }
              });
            this.oldTreeData = JSON.parse(JSON.stringify(res.data.data));
            this.textTreeData = JSON.parse(JSON.stringify(res.data.data));
            if (name !== "编辑") {
              this.$nextTick(() => {
                this.selectTreeData.forEach((item) => {
                  this.$refs.textTreeNode.setChecked(item.uuid, true);
                  item.norms.forEach((itm) => {
                    this.$refs.textTreeNode.setChecked(itm.uuid, true);
                  });
                });
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    text_save() {
      this.dialogTextIndex = false;
      this.demoSelectData = [];
      this.saveSelectTreeData = JSON.parse(JSON.stringify(this.selectTreeData));
      if (!this.saveSelectTreeData.length) {
        this.GJ_tableData = [];
        return;
      }
      this.GJObject = [];
      this.saveSelectTreeData.forEach((item) => {
        if (item.norms.length) {
          item.norms.forEach((itm) => {
            this.demoSelectData.push(itm);
          });
        } else {
          this.demoSelectData.push(item);
        }

        let obj = {};
        obj.cate_name = item.name;
        obj.cate_uuid = item.uuid;
        obj.norms = [];
        item.norms.forEach((itm) => {
          obj.norms.push({
            norm_name: itm.name,
            norm_uuid: itm.uuid,
            norm_value: "",
            value_type: "0",
            json_value: [],
            unit_name: itm.unit_name,
          });
        });
        this.GJObject.push(obj);
      });
      if (this.GJ_tableData.length) {
        this.GJObject.forEach((item, index) => {
          this.GJ_tableData.forEach((itm, idx) => {
            var type = true;
            itm.cates.forEach((im, ix) => {
              if (item.cate_name == im.cate_name) {
                type = false;
                if (item.norms.length !== 0) {
                  item.norms.forEach((i, x) => {
                    var c_type = true;
                    im.norms.forEach((o, m) => {
                      if (i.norm_name == o.norm_name) {
                        c_type = false;
                      }
                    });
                    if (c_type) {
                      im.norms.push(JSON.parse(JSON.stringify(i)));
                    }
                  });
                }
              }
            });
            if (type) {
              itm.cates.push(JSON.parse(JSON.stringify(item)));
            }
          });
        });

        this.newDelData = JSON.parse(JSON.stringify(this.GJ_tableData));
        var that = this;
        this.GJ_tableData[0].cates.forEach((item, index) => {
          var type = true;
          var c_name = item.cate_name;
          this.GJObject.forEach((itm) => {
            if (item.cate_name == itm.cate_name) {
              type = false;
              if (item.norms.length) {
                item.norms.forEach((im, ix) => {
                  var c_type = true;
                  var name = im.norm_name;
                  itm.norms.forEach((i, x) => {
                    if (im.norm_name == i.norm_name) {
                      c_type = false;
                    }
                  });
                  if (c_type) {
                    this.delterCates("s", index, ix, name, c_name);
                  }
                });
              }
            }
          });
          if (type) {
            this.delterCates("f", index, "", c_name);
          }
        });
      }
      this.GJ_tableData = JSON.parse(JSON.stringify(this.newDelData));
      this.getSlectName();
      this.sportsmanChange();
    },
    delterCates(name, index, ix, u_name, c_name) {
      if (name == "f") {
        this.newDelData.forEach((item, idx) => {
          item.cates.forEach((cm, cx) => {
            if (cm.cate_name == u_name) {
              item.cates.splice(cx, 1);
            }
          });
        });
      } else {
        this.newDelData.forEach((item) => {
          item.cates.forEach((am, ax) => {
            if (am.cate_name == c_name) {
              am.norms.forEach((im, ax) => {
                if (im.norm_name == u_name) {
                  am.norms.splice(ax, 1);
                }
              });
            }
          });
        });
      }
    },
    demoCancel(uid, index) {
      this.demoSelectData.splice(index, 1);
      this.GJObject.forEach((item, ix) => {
        if (item.cate_uuid == uid) {
          this.GJObject.splice(ix, 1);
        } else if (item.norms.length) {
          item.norms.forEach((itm, idx) => {
            if (itm.norm_uuid == uid) {
              item.norms.splice(idx, 1);
              if (!item.norms.length) {
                this.GJObject.splice(ix, 1);
              }
            }
          });
        }
      });
      this.saveSelectTreeData.forEach((item, idx) => {
        if (item.uuid == uid) {
          this.saveSelectTreeData.splice(idx, 1);
        } else if (item.norms.length) {
          item.norms.forEach((im, ix) => {
            if (im.uuid == uid) {
              item.norms.splice(ix, 1);
            }
          });
          if (!item.norms.length) {
            this.saveSelectTreeData.splice(idx, 1);
          }
        }
      });
      if (this.saveSelectTreeData == []) {
        this.GJObject = [];
      }

      this.newDelData = JSON.parse(JSON.stringify(this.GJ_tableData));
      this.GJ_tableData[0].cates.forEach((item, index) => {
        var type = true;
        var c_name = item.cate_name;
        this.GJObject.forEach((itm) => {
          if (item.cate_name == itm.cate_name) {
            type = false;
            if (item.norms.length) {
              item.norms.forEach((im, ix) => {
                var c_type = true;
                var name = im.norm_name;
                itm.norms.forEach((i, x) => {
                  if (im.norm_name == i.norm_name) {
                    c_type = false;
                  }
                });
                if (c_type) {
                  this.delterCates("s", index, ix, name, c_name);
                }
              });
            }
          }
        });
        if (type) {
          this.delterCates("f", index, "", c_name);
        }
      });
      this.GJ_tableData = JSON.parse(JSON.stringify(this.newDelData));
      this.getSlectName();
      this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
    },
    text_cancel() {
      this.dialogTextIndex = false;
      this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData));
    },
    clickTreeAll() {
      this.selectTreeData.forEach((item, index) => {
        this.$refs.textTreeNode.setChecked(item.uuid, false);
        item.norms.forEach((itm) => {
          this.$refs.textTreeNode.setChecked(itm.uuid, false);
        });
      });
      this.selectTreeData = [];
      this.selectNum = 0;
    },
    textTreeCancel(node, data) {
      this.$refs.textTreeNode.setChecked(data.uuid, false);
      this.selectTreeData.forEach((item, index) => {
        if (item.uuid == data.uuid) {
          item.norms.forEach((itm) => {
            this.$refs.textTreeNode.setChecked(itm.uuid, false);
          });
          this.selectTreeData.splice(index, 1);
        } else if (item.norms !== []) {
          item.norms.forEach((itm, idx) => {
            if (itm.uuid == data.uuid) {
              item.norms.splice(idx, 1);
              if (item.norms.length == 0) {
                this.selectTreeData.splice(index, 1);
              }
            }
          });
        }
      });
      this.selectNum = 0;
      this.selectTreeData.forEach((item) => {
        if (item.norms.length) {
          this.selectNum += item.norms.length;
        } else {
          this.selectNum += 1;
        }
      });
    },
    checkChange(node, data) {
      let is_fit = true;

      var selectData = JSON.parse(JSON.stringify(this.selectTreeData));
      selectData.forEach((item, index) => {
        if (item.uuid == node.uuid) {
          is_fit = false;
          if (item.norms.length == node.norms.length) {
            selectData.splice(index, 1);
          } else {
            item.norms = node.norms;
          }
        } else if (item.norms !== []) {
          item.norms.forEach((itm, idx) => {
            if (itm.uuid == node.uuid) {
              is_fit = false;
              selectData[index].norms.splice(idx, 1);
              if (item.norms.length == 0) {
                selectData.splice(index, 1);
              }
            }
          });
        }
      });
      this.selectTreeData = selectData;
      if (is_fit) {
        var treeData = JSON.parse(JSON.stringify(this.textTreeData));
        treeData.forEach((im, ix) => {
          var ims = JSON.parse(JSON.stringify(im));
          if (ims.uuid == node.uuid) {
            this.selectTreeData.push(ims);
          } else if (ims.norms !== []) {
            ims.norms.forEach((i, x) => {
              if (i.uuid == node.uuid) {
                let type = true;
                this.selectTreeData.forEach((o, m) => {
                  if (o.uuid == ims.uuid) {
                    type = false;
                    o.norms.push(i);
                  }
                });
                if (type) {
                  ims.norms = [];
                  ims.norms.push(i);
                  this.selectTreeData.push(ims);
                }
              }
            });
          }
        });
      }
      this.selectNum = 0;
      this.selectTreeData.forEach((item) => {
        if (item.norms.length) {
          this.selectNum += item.norms.length;
        } else {
          this.selectNum += 1;
        }
      });
    },
    exportSave() {
      let a_data = JSON.parse(JSON.stringify(this.GJ_tableData));
      if (a_data.length) {
        if (this.GJoldman !== 3) {
          a_data.pop();
        } else {
          a_data.pop();
          a_data.pop();
        }
      }
      // 过滤
      // debugger
      // var forDedtailData = JSON.parse(JSON.stringify(a_data))
      // a_data.forEach((item, index) => {
      //   var ftype = true, cname = item.cate_name
      //   this.selectTreeData.forEach((itm, idx) => {
      //     if(item.cate_name == itm.name) {
      //       ftype = false
      //     }
      //   })
      //   if(ftype) {
      //     forDedtailData.forEach((item, ix) => {
      //       if(item.cate_name == cname) {
      //         forDedtailData.splice(ix, 1)
      //       }
      //     })
      //   }
      // })

      // a_data = JSON.parse(JSON.stringify(forDedtailData))

      let url = "/endurance/add";
      let data = {
        title: this.ruleForm.title,
        test_date: this.ruleForm.date,
        department_uuid: this.ruleForm.department_uuid,
        staff_uuid_str: this.ruleForm.sportsmanId.toString(","),
        coach: this.ruleForm.coach,
        address_uuid: this.ruleForm.address,
        type: "6",
        demo: this.ruleForm.demo,
        cate_norm: JSON.stringify(this.selectTreeData),
        detail: JSON.stringify(a_data),
        summary: this.editorContent,
      };
      if (this.$route.query.uuid) {
        url = "/endurance/update";
        data.uuid = this.$route.query.uuid;
      }
      this.$axios
        .post(url, this.$qs(data))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.$router.push({
              path: "/textItem/enduranceText",
              query: { type: 6 },
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        row.staff_name == "男子-团队平均值" ||
        row.staff_name == "女子-团队平均值"
      ) {
        if (columnIndex === 0) {
          return [0, 0];
        } else if (columnIndex === 1) {
          return [1, 3];
        } else if (columnIndex === 2) {
          return [0, 0];
        }
      }
    },
    ruleValueChange(name, s_index, index, idx) {
      let type = "";
      this.oldTreeData.forEach((item) => {
        item.norms.forEach((itm) => {
          if (itm.name == name) {
            type = itm.value_type;
            return false;
          }
        });
      });
      let value = this.GJ_tableData[s_index].cates[index].norms[idx].norm_value;
      if (type == "1") {
        var regExp = /^[0-9][0-9]*[.]?([0-9]+)?$/;
        if (!regExp.test(value)) {
          this.GJ_tableData[s_index].cates[index].norms[idx].norm_value = "";
        }
      }
      console.log(this.GJ_tableData);
    },
    sportsmanChange() {
      let index = this.ruleForm.sportsmanId.indexOf("0");
      if (index !== -1) {
        let data = [];
        this.sportsmanList.forEach((itm) => {
          data.push(itm.uuid);
        });
        this.ruleForm.sportsmanId = data;
        this.ruleForm.sportsmanId.splice(0, 1);
      }

      if (!this.selectTreeData.length) {
        return false;
      }
      if (this.GJoldman == 1 || this.GJoldman == 2) {
        this.GJ_tableData.pop();
      } else if (this.GJoldman == 3) {
        this.GJ_tableData.pop();
        this.GJ_tableData.pop();
      }
      this.ruleForm.sportsmanId.forEach((item) => {
        this.sportsmanList.forEach((itm) => {
          if (item == itm.uuid) {
            let type = true;
            this.GJ_tableData.forEach((im, ix) => {
              if (im.staff_uuid == item) {
                type = false;
              }
            });
            if (type) {
              this.GJ_tableData.push({
                staff_uuid: itm.uuid,
                staff_name: itm.name,
                sex: itm.sex,
                cates: JSON.parse(JSON.stringify(this.GJObject)),
              });
            }
          }
        });
      });
      this.GJ_tableData.forEach((item, index) => {
        let o_type = true;
        this.ruleForm.sportsmanId.forEach((itm) => {
          if (item.staff_uuid == itm) {
            o_type = false;
          }
        });
        if (o_type) {
          this.GJ_tableData.splice(index, 1);
        }
      });
      let man = 0;
      this.GJ_tableData.forEach((item, index) => {
        if (!man) {
          man = item.sex;
        }
        if (item.sex !== man) {
          man = 3;
        }
      });
      if (man == 1) {
        this.GJ_tableData.push({
          staff_uuid: "",
          staff_name: "男子-团队平均值",
          sex: 1,
          cates: JSON.parse(JSON.stringify(this.GJObject)),
        });
      } else if (man == 2) {
        this.GJ_tableData.push({
          staff_uuid: "",
          staff_name: "女子-团队平均值",
          sex: 2,
          cates: JSON.parse(JSON.stringify(this.GJObject)),
        });
      } else if (man == 3) {
        this.GJ_tableData.push({
          staff_uuid: "",
          staff_name: "男子-团队平均值",
          sex: 1,
          cates: JSON.parse(JSON.stringify(this.GJObject)),
        });
        this.GJ_tableData.push({
          staff_uuid: "",
          staff_name: "女子-团队平均值",
          sex: 2,
          cates: JSON.parse(JSON.stringify(this.GJObject)),
        });
      }
      this.GJoldman = man;
      setTimeout(() => {
        this.ALLMean();
      }, 0);
    },
    getSlectName() {
      this.cateNameData = [];
      this.Options = {};
      this.JROptions = [];
      this.HXOptions = [];
      this.NLOptions = [];
      this.GJObject.forEach((item) => {
        this.cateNameData.push(item.cate_name);
        this.Options[item.cate_name] = {
          sex: "",
          select: "",
          norms: [],
        };
        item.norms.forEach((itm, x) => {
          if (x == 0) {
            this.Options[item.cate_name].select = itm.norm_name;
          }
          this.Options[item.cate_name].norms.push(itm.norm_name);
        });
      });
    },
    ALLMean(sex, s_index, index, idx, name, c_name) {
      let manIndex = -1,
        womanIndex = -1;

      if (!index && index != 0) {
        this.GJ_tableData.forEach((item, i) => {
          if (item.staff_name == "男子-团队平均值") {
            manIndex = i;
          } else if (item.staff_name == "女子-团队平均值") {
            womanIndex = i;
          }
        });
        this.GJObject.forEach((item, index) => {
          item.norms.forEach((itm, idx) => {
            this.getSum(
              item.cate_name,
              itm.norm_name,
              manIndex,
              womanIndex,
              index,
              idx
            );
          });
        });
      } else {
        var manNormNum = 0,
          womanNormNum = 0,
          manNormSum = 0,
          womanNormSum = 0;
        this.GJ_tableData.forEach((item, i) => {
          if (item.staff_name == "男子-团队平均值") {
            manIndex = i;
          } else if (item.staff_name == "女子-团队平均值") {
            womanIndex = i;
          } else if (item.sex == 1) {
            if (
              item.cates[index].norms[idx].norm_value !== "" &&
              item.cates[index].norms[idx].norm_value !== null &&
              item.cates[index].norms[idx].norm_value !== "null"
            ) {
              manNormNum++;
              manNormSum += Number(item.cates[index].norms[idx].norm_value);
            }
          } else if (item.sex == 2) {
            if (
              item.cates[index].norms[idx].norm_value !== "" &&
              item.cates[index].norms[idx].norm_value !== null &&
              item.cates[index].norms[idx].norm_value !== "null"
            ) {
              womanNormNum++;
              womanNormSum += Number(item.cates[index].norms[idx].norm_value);
            }
          }
        });
        if (manNormNum !== 0) {
          this.GJ_tableData[manIndex].cates[index].norms[idx].norm_value = (
            manNormSum / manNormNum
          ).toFixed(1);
        } else if (manIndex !== -1) {
          this.GJ_tableData[manIndex].cates[index].norms[idx].norm_value = "";
        }

        if (womanNormNum !== 0) {
          this.GJ_tableData[womanIndex].cates[index].norms[idx].norm_value = (
            womanNormSum / womanNormNum
          ).toFixed(1);
        } else if (womanIndex !== -1) {
          this.GJ_tableData[womanIndex].cates[index].norms[idx].norm_value = "";
        }
      }
      if (!index && index != 0) {
        this.$nextTick(() => {
          this.cateNameData.forEach((a, b) => {
            this.YBTLine(a, b);
          });
        });
      } else {
        this.$nextTick(() => {
          this.YBTLine(name, index);
        });
      }
    },

    getSum(c_name, n_name, manIndex, womanIndex, index, idx) {
      var manNormNum = 0,
        womanNormNum = 0,
        manNormSum = 0,
        womanNormSum = 0;
      this.GJ_tableData.forEach((item) => {
        item.cates.forEach((itm) => {
          if (itm.cate_name == c_name) {
            itm.norms.forEach((im) => {
              if (im.norm_name == n_name) {
                if (
                  item.sex == "1" &&
                  im.norm_value !== "" &&
                  im.norm_value !== null &&
                  im.norm_value !== "null"
                ) {
                  manNormNum++;
                  manNormSum += Number(im.norm_value);
                } else if (
                  item.sex == "2" &&
                  im.norm_value !== "" &&
                  im.norm_value !== null &&
                  im.norm_value !== "null"
                ) {
                  womanNormNum++;
                  womanNormSum += Number(im.norm_value);
                }
              }
            });
          }
        });
        if (manNormNum !== 0) {
          this.GJ_tableData[manIndex].cates[index].norms[idx].norm_value = (
            manNormSum / manNormNum
          ).toFixed(1);
        } else if (manIndex !== -1) {
          this.GJ_tableData[manIndex].cates[index].norms[idx].norm_value = "";
        }
        if (womanNormNum !== 0) {
          this.GJ_tableData[womanIndex].cates[index].norms[idx].norm_value = (
            womanNormSum / womanNormNum
          ).toFixed(1);
        } else if (womanIndex !== -1) {
          this.GJ_tableData[womanIndex].cates[index].norms[idx].norm_value = "";
        }
      });
    },
    sexChange(item, index) {
      this.$forceUpdate();
      this.YBTLine(item, index);
    },
    selectSort(arr) {
      if (Array.isArray(arr)) {
        for (var i = arr.length - 1; i > 0; i--) {
          for (var j = 0; j < i; j++) {
            if (Number(arr[j].value) < Number(arr[j + 1].value)) {
              [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
            }
          }
        }
        return arr;
      }
    },

    // -------- Echarts ------------------
    YBTLine(name, index) {
      let edata = JSON.parse(JSON.stringify(this.GJ_tableData));

      if (this.GJoldman !== 3) {
        edata.pop();
      } else {
        edata.pop();
        edata.pop();
      }
      if (this.Options[name].sex != "") {
        let data = [];
        edata.forEach((item) => {
          if (item.sex == this.Options[name].sex) {
            data.push(item);
          }
        });
        edata = data;
      }

      let manData = [],
        manNum = 0,
        manSum = 0;
      let e_data = [];
      edata.forEach((item) => {
        item.cates.forEach((itm) => {
          if (itm.cate_name == name) {
            itm.norms.forEach((im) => {
              if (im.norm_name == this.Options[name].select) {
                manData.push({
                  sex: item.sex,
                  value: im.norm_value,
                  staff_name: item.staff_name,
                });
              }
            });
          }
        });
      });
      manData = this.selectSort(manData);

      let num = 0,
        sum = 0;
      if (this.Options[name].sex !== "") {
        edata.forEach((item) => {
          item.cates.forEach((itm) => {
            if (itm.cate_name == name) {
              itm.norms.forEach((im) => {
                if (im.norm_name == this.Options[name].select) {
                  if (
                    im.norm_value !== "" &&
                    item.sex == this.Options[name].sex
                  ) {
                    num++;
                    sum += Number(im.norm_value);
                  }
                }
              });
            }
          });
        });
      } else {
        edata.forEach((item) => {
          item.cates.forEach((itm) => {
            if (itm.cate_name == name) {
              itm.norms.forEach((im) => {
                if (im.norm_name == this.Options[name].select) {
                  if (im.norm_value !== "") {
                    num++;
                    sum += Number(im.norm_value);
                  }
                }
              });
            }
          });
        });
      }
      let unit = "";
      this.oldTreeData.forEach((item) => {
        item.norms.forEach((itm) => {
          if (itm.name == this.Options[name].select) {
            unit = itm.unit_name;
          }
        });
      });
      let e_name = "Line" + index;
      let serum = this.$echarts.init(document.getElementById(e_name));
      let optionS = {
        title: {
          x: "center",
          text: name + " - " + this.Options[name].select,
          textStyle: {
            //标题内容的样式
            color: "#333333", //京东红
            fontStyle: "normal", //主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight: "normal", //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily: "MicrosoftYaHei", //主题文字字体，默认微软雅黑
            fontSize: 16, //主题文字字体大小，默认为18px
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter:
            this.GJoldman == 3 && this.Options[name].sex == ""
              ? "{b}<br />" + this.Options[name].select + ": {c}{c1}"
              : "{b}<br />" + this.Options[name].select + ": {c}",
        },
        legend: {
          data: ["男", "女"],
          // right: 40,
          top: 50,
          left: "center",
          icon: "rect", //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 10, // 设置宽度
          itemHeight: 10, // 设置高度
          itemGap: 20, // 设置间距
          itemStyle: {},
        },
        color: ["#1790FF", "#F79645"],
        grid: {
          left: "2%",
          right: "0%",
          bottom: "3%",
          top: "29%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: manData.map((item) => {
            return item.staff_name;
          }),
          axisLabel: {
            color: "#333",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#E1E1E1",
            },
          },
        },
        yAxis: {
          show: true,
          name: unit ? "单位: " + unit : "单位: ",
          type: "value",
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: "#E1E1E1",
              type: "dashed",
            },
          },
          axisLine: { show: false, lineStyle: { color: "#333" } },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            padding: [0, 0, 25, 0],
          },
        },
        series: [
          {
            name: "辅助",
            type: "bar",
            stack: "总量",
            itemStyle: {
              barBorderColor: "rgba(0,0,0,0)",
              color: "rgba(0,0,0,0)",
            },
            emphasis: {
              itemStyle: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
            },
            barWidth: "16",
          },
          {
            name: "男",
            type: "bar",
            stack: "总量",
            label: {
              show:
                this.Options[name].sex == "1" || this.Options[name].sex == ""
                  ? true
                  : false,
              position: "top",
            },
            markLine: {
              silent: true, //true 去掉鼠标悬浮该线上的动画
              symbol: "none", //该线无样式
              label: {
                width: "1",
                show: true, //该线上的值去掉,
                position: "insideEndTop",
              },
              lineStyle: {
                //设置该线样式
                normal: {
                  width: "1",
                  type: "dashed",
                  color: "#2FC25B",
                  formatter: "全队平均值 : 100",
                },
              },
              data: [
                {
                  yAxis: (sum / num).toFixed(1), //线的值
                  name:
                    this.Options[name].sex == "1"
                      ? "男子平均值"
                      : this.Options[name].sex == "2"
                      ? "女子平均值"
                      : "全队平均值",
                  label: {
                    show: true,
                    formatter: "{b}: {c}",
                  },
                },
              ],
            },
            // data: [900, 345, 393, '-', '-', 135, 178, 286, '-', '-', '-']
            data: manData.map((item) => {
              if (item.sex == "1") {
                return item.value;
              }
            }),
          },
          {
            name: "女",
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "top",
            },
            markLine: {
              silent: true, //true 去掉鼠标悬浮该线上的动画
              symbol: "none", //该线无样式
              label: {
                width: "1",
                show: true, //该线上的值去掉,
                position: "insideEndTop",
              },
              lineStyle: {
                //设置该线样式
                normal: {
                  width: "1",
                  type: "dashed",
                  color: "#2FC25B",
                  width: this.GJoldman == 2 ? 1 : 0,
                },
              },
              data: [
                {
                  yAxis: (sum / num).toFixed(1), //线的值
                  name:
                    this.Options[name].sex == "1"
                      ? "男子平均值"
                      : this.Options[name].sex == "2"
                      ? "女子平均值"
                      : "全队平均值",
                  label: {
                    show: true,
                    formatter: "{b}: {c}",
                  },
                },
              ],
            },
            data: manData.map((item) => {
              if (item.sex == "2") {
                return item.value;
              }
            }),
          },
        ],
      };
      serum.setOption(optionS);
      this.$echartsResize(serum);
    },
  },
};
</script>

<style lang="scss" scoped>
.body_functionAdd_top {
  background: #fff;
  height: auto;
  padding: 10px 100px 30px 60px;
  border-radius: 4px;
}
// ------------------
// 关节角度
.GJ_content {
  background: #fff;
  height: auto;
  padding: 40px 100px 30px 50px;
  margin-top: 10px;
  border-radius: 4px;
  .top_title {
    margin-left: 0;
  }
  .GJ_tableData {
    margin-top: 20px;
  }
  .RRX_echarts {
    height: 400px;
    position: relative;
    .wl_e_line {
      width: 100%;
      height: 100%;
    }
    .e_left_sele {
      position: absolute;
      top: 45px;
      right: 0%;
      z-index: 9999;
      .el-select:first-child {
        width: 120px;
        margin-right: 80px;
      }
      .el-select:last-child {
        width: 120px;
      }
    }
  }
  .JR_echarts {
    height: 400px;
    position: relative;
    margin-top: 40px;
    .wl_e_line {
      width: 100%;
      height: 100%;
    }
    .e_left_sele {
      position: absolute;
      top: 45px;
      right: 0%;
      z-index: 9999;
      .el-select:first-child {
        width: 120px;
        margin-right: 20px;
      }
      .el-select:last-child {
        width: 120px;
      }
    }
  }
}
.GJ_echarts {
  background: #fff;
  height: auto;
  padding: 0 100px 0 50px;
  margin-top: 10px;
  border-radius: 4px;
  .top_title {
    margin-left: 0;
    padding-top: 40px;
  }
  .GJ_tableData {
    margin-top: 20px;
  }
  .RRX_echarts {
    height: 400px;
    position: relative;
    .wl_e_line {
      width: 100%;
      height: 100%;
    }
    .e_left_sele {
      position: absolute;
      top: 45px;
      right: 0%;
      z-index: 9999;
      .el-select:first-child {
        width: 120px;
        margin-right: 80px;
      }
      .el-select:last-child {
        width: 120px;
      }
    }
  }
  .JR_echarts {
    height: 400px;
    position: relative;
    margin-top: 40px;
    .wl_e_line {
      width: 100%;
      height: 100%;
    }
    .e_left_sele {
      position: absolute;
      top: 45px;
      right: 0%;
      z-index: 9999;
      .el-select:first-child {
        width: 120px;
        margin-right: 20px;
      }
      .el-select:last-child {
        width: 120px;
      }
    }
    &:last-child {
      padding-bottom: 30px;
    }
  }
}
// ------------------
// 测试评价
.test_content {
  background: #fff;
  height: auto;
  padding: 40px 100px 30px 50px;
  margin-top: 10px;
  border-radius: 4px;
  .top_title {
    margin-left: 0;
  }
  .test_editor {
    width: 100%;
    margin-top: 40px;
    height: 400px;
  }
  .look_editor {
    overflow: auto;
    border: 1px solid #f5f7fa;
    border-radius: 5px;
    padding: 20px;
  }
}
.child-top {
  align-items: flex-end;
  margin-bottom: 0;
}
.operation-button {
  .fanhui {
    font-size: 16px;
    color: #666;
    display: inline-block;
    padding-top: 5px;
    margin-left: 40px;
    margin-right: 20px;
    i {
      margin-right: 5px;
      color: #000;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.top_title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
  margin-left: 30px;
  span {
    display: inline-block;
    width: 6px;
    height: 20px;
    background: #0055e9;
    border-radius: 3px;
    margin-right: 10px;
  }
}
.demo_ruleForm {
  .item_flex {
    display: flex;
    justify-content: space-between;
    .el-form-item {
      width: 45%;
    }
  }
  .look_opp {
    border-bottom: 2px solid #cccccc;
    color: #666;
    padding-left: 10px;
    min-height: 40px;
    line-height: 40px;
  }
}
.addressForm_dialog {
  padding: 0 20px 50px;
}
.form-bottom-button {
  padding-bottom: 30px;
}
/deep/ .bottom_btn {
  .el-input .el-input__inner {
    border-radius: 0;
    border-right-width: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }

  .el-input .el-input__inner:focus {
    border-color: #0055e9;
  }

  .el-select {
    float: left;
  }

  .el-select .el-input__inner {
    border-right-width: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #ccc;
  }

  .el-select .el-input__inner:focus {
    border-color: #0055e9;
  }

  .el-select .el-input__inner:focus {
    border-right-width: 1px;
  }

  .bot_content {
    p {
      display: flex;
      align-items: center;
      width: 100%;

      i {
        font-size: 22px;
      }

      span {
        font-size: 18px;
      }
    }
  }
}
.text_demo {
  width: 99.4%;
  min-height: 35px;
  border-bottom: 2px solid #ccc;
  padding-left: 5px;
  span {
    padding: 5px 3px 5px 10px;
    border-radius: 5px;
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    font-size: 14px;
    margin-right: 7px;
    margin-bottom: 3px;
    i {
      border-radius: 50%;
      background-color: #c0c4cc;
      font-size: 14px;
      color: #909399;
      margin-left: 5px;
      &:hover {
        color: #fff;
        cursor: pointer;
      }
    }
  }
  p {
    padding-left: 10px;
    color: #c2c2c2;
    font-size: 14px;
  }
}
.address_del {
  &:hover {
    color: #f00;
    cursor: pointer;
  }
}
.ModifyDoalog {
  display: flex;
  height: 400px;
  padding: 0 30px;
  justify-content: space-between;
  margin-bottom: 10px;
  .textDialog_left {
    width: 45%;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    .textDialog_left_input {
      padding: 10px 20px;
    }
    h2 {
      height: 50px;
      line-height: 50px;
      background: #f5f5f5;
      border-radius: 2px 2px 0px 0px;
      border: 1px solid #e3e3e3;
      border-left: none;
      border-right: none;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #333;
      margin-top: 5px;
    }
    .tree_demo {
      height: 280px;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      .el-tree {
        font-size: 16px;
        /deep/ .el-tree-node {
          margin: 5px 0;
          .el-checkbox {
            margin-right: 10px;
            .is-checked {
              .el-checkbox__inner {
                background-color: #0055e9;
                border-color: #0055e9;
              }
            }
            .is-indeterminate {
              .el-checkbox__inner {
                background-color: #0055e9;
                border-color: #0055e9;
              }
            }
          }
          .el-tree-node__label {
            color: #333;
          }
        }
      }
    }
  }
  .textDialog_right {
    width: 45%;
    border: 1px solid #e3e3e3;
    p {
      height: 50px;
      line-height: 50px;
      background: #f5f5f5;
      border-radius: 2px 2px 0px 0px;
      border-bottom: 1px solid #e3e3e3;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #333;
      position: relative;
      span {
        position: absolute;
        right: 20px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #999999;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .tree_demo {
      height: 345px;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      .el-tree {
        font-size: 16px;
        /deep/ .el-tree-node {
          margin: 5px 0;
          .el-checkbox {
            margin-right: 10px;
            .is-checked {
              .el-checkbox__inner {
                background-color: #0055e9;
                border-color: #0055e9;
              }
            }
            .is-indeterminate {
              .el-checkbox__inner {
                background-color: #0055e9;
                border-color: #0055e9;
              }
            }
          }
          .el-tree-node__label {
            color: #333;
          }
        }

        .custom-tree-node {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding-right: 8px;
          i {
            &:hover {
              color: #f00;
            }
          }
        }
      }
    }
  }
}
.bz_content {
  margin-bottom: 40px;
  padding-left: 30px;
  line-height: 30px;
  color: #f00;
}
</style>
<style scoped>
.el-input {
  margin-bottom: 1px;
  box-sizing: border-box;
}
</style>